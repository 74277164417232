import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import toast, { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/payment.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./custom.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import { CircularProgress, Typography } from "@mui/material";
import PaymentHashErrorMsg from "components/Dialog/PaymentHashErrorMsg";

function NFTPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [hash, setHash] = useState("");
  const [address, setAddress] = useState("");
  const [formError, setFormError] = useState({ hash: "", address: "" });

  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isErrorPaymentHashDialogOpen, setIsErrorPaymentHashDialogOpen] =
    useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  const isValidTransactionHash = (input) => /^[a-fA-F0-9]{64}$/.test(input);
  const isValidWalletAddress = (input) => /^0x[a-fA-F0-9]{40}$/.test(input);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(process.env.REACT_APP_TRANSACTIONCODE)
      .then(() => {
        toast.success("Address copied to clipboard!");
      });
  };

  const handlePaymentFormSubmit = async (event) => {
    event.preventDefault();
    let errors = {};
    if (!isValidTransactionHash(hash)) {
      errors.hash =
        "Invalid transaction hash. Must be 64 hexadecimal characters.";
    }
    if (!isValidWalletAddress(address)) {
      errors.address =
        "Invalid wallet address. Must start with '0x' followed by 40 hexadecimal characters.";
    }

    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return;
    }

    // if (!isValidTransactionHash(hash)) {
    //   setFormError(true);
    //   setErrorMessage(
    //     "Please enter a valid transaction hash (minimum 64 characters)."
    //   );
    //   setTitleDialogMessage("Invalid Transaction Hash!");
    //   setIsErrorDialogOpen(true);
    //   return;
    // }

    // if (!isValidWalletAddress(address)) {
    //   setFormError(true);
    //   setErrorMessage(
    //     "The address you have entered is Invalid. Please try again."
    //   );
    //   setTitleDialogMessage("Invalid Address!");
    //   setIsErrorDialogOpen(true);
    //   return;
    // }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/nft-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ hash, secondaddress: address }),
        }
      );

      if (response.ok) {
        await response.json();
        setTitleDialogMessage("Payment Submitted");
        setSuccessMessage(
          "Your payment request has been successfully submitted. Please wait for further processing."
        );
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(
          errorData.message || "An unknown error occurred. Please try again."
        );
        setTitleDialogMessage("Payment Error");
        setIsErrorDialogOpen(true);
        setIsErrorPaymentHashDialogOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "There was an issue submitting your request. Please check your connection and try again."
      );
      setTitleDialogMessage("Submission Error");
      setIsErrorDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleDialogErrorPaymentHashClose = () => {
    setIsErrorPaymentHashDialogOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        {loading ? (
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
          >
            <CircularProgress size={60} color="primary" />
            <Typography variant="h6" ml={2}>
              Loading your data...
            </Typography>
          </MDBox>
        ) : (
          <>
            <Grid container spacing={6} mt={1}>
              <Grid item xs={12}>
                <MDBox textAlign="center">
                  <MDTypography variant="h3" mb={3}>
                    Scan QR Code!
                  </MDTypography>
                  <img
                    alt="document"
                    src={DocumentDarkImage}
                    style={{ cursor: "pointer", width: isMobile ? "35%" : 130 }}
                  />

                  <MDBox
                    textAlign="center"
                    mb={2}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <MDTypography variant="h6" sx={{ mr: 1 }}>
                      {process.env.REACT_APP_TRANSACTIONCODE}
                    </MDTypography>
                    <Tooltip title="Copy">
                      <IconButton onClick={handleCopy} aria-label="copy">
                        {" "}
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <MDBox
                  textAlign="center"
                  sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                      {" "}
                      Open any wallet and pay on this address
                    </MDTypography>{" "}
                    <MDTypography
                      variant="h3"
                      color="error"
                      className="animated-text"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {" "}
                      Pay USDT on Tron Blockchain
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handlePaymentFormSubmit}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    mt={3}
                  >
                    <TextField
                      required
                      label="Transaction Hash"
                      placeholder="Enter or paste your transaction hash here"
                      error={!!formError.hash}
                      helperText={formError.hash}
                      value={hash}
                      onChange={(e) => setHash(e.target.value)}
                      sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                    />

                    <TextField
                      required
                      label="Wallet Address"
                      placeholder="Enter or paste your wallet address here"
                      error={!!formError.address}
                      helperText={formError.address}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      sx={{ width: { xs: "90%", sm: "75%", md: "50%" } }}
                    />

                    <MDButton
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        background:
                          "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": { backgroundColor: "darkgreen" },
                        m: 2,
                      }}
                    >
                      Submit
                    </MDButton>
                  </MDBox>

                  <ErrorDialog
                    open={isErrorDialogOpen}
                    handleClose={handleDialogClose}
                    title={titleDialogMessage}
                    message={errorMessage}
                  />
                  <SuccessDialog
                    open={isSuccessDialogOpen}
                    handleClose={handleDialogSuccessClose}
                    title={titleDialogMessage}
                    message={successMessage}
                    redirectUrl="/profile"
                  />
                  <PaymentHashErrorMsg
                    openProp={isErrorPaymentHashDialogOpen}
                    handleClose={handleDialogErrorPaymentHashClose}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default NFTPayment;
