import Grid from "@mui/material/Grid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import pattern from "assets/images/profile-bg.png";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import MyDocument from "components/PDF";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  const formattedTime = date.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
};
function Profile() {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [passport_number, setPassportNumber] = useState("");

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails || "");

      setFirstName(userDetails.personalDetails?.first_name || "");
      setLastName(userDetails.personalDetails?.last_name || "");
      setPassportNumber(userDetails.personalDetails?.passport_number || "");
    }
  }, [userDetails]);

  // const rawNftData = localStorage.getItem("nftdata");
  // const nftMinting = !isNaN(rawNftData) ? rawNftData : 0;
  // console.log(userData);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={12}>
                  <Card
                    sx={{
                      background:
                        "linear-gradient(0deg, #222222 48.62%, #1E3811 100%)",
                      position: "relative",
                    }}
                  >
                    <MDBox
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      sx={{
                        backgroundImage: `url(${pattern})`,
                        backgroundSize: "cover",
                      }}
                    />
                    <MDBox position="relative" zIndex={2} p={2}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {" "}
                        <MDBox
                          color="white"
                          p={1}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <Icon fontSize="default">person</Icon>
                        </MDBox>
                        <MDBox ml="auto" lineHeight={0}>
                          <MDButton
                            variant="outlined"
                            color="white"
                            onClick={handleClickOpen}
                            sx={{ background: "transparent" }}
                            disabled={
                              userData &&
                              (userData.status === "Under_review" ||
                                userData.status === "Processing" ||
                                userData.status === "Block" ||
                                userData.status === "Rejected" ||
                                userData.status === "Completed")
                                ? true
                                : false
                            }
                          >
                            <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                            &nbsp;Edit Profile
                          </MDButton>
                        </MDBox>
                      </MDBox>

                      <MDBox
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        flexWrap="wrap"
                        justifyContent="space-between"
                        gap={1}
                        sx={{ mt: 2, pb: 1.5 }}
                      >
                        <MDBox
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Wallet Address: &nbsp;
                          </MDTypography>{" "}
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="regular"
                            opacity={0.8}
                            sx={{
                              fontSize: 14,
                              wordBreak: "break-all",
                              maxWidth: "100%",
                            }}
                          >
                            {userData && userData.address
                              ? userData.address
                              : "-"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        flexWrap="wrap"
                        justifyContent="space-between"
                        gap={1}
                        sx={{ pb: 1.5 }}
                      >
                        <MDBox
                          display="flex"
                          flexDirection={{ xs: "column", md: "row" }}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            First Name: &nbsp;
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="regular"
                            opacity={0.8}
                            mr={6}
                          >
                            {userData && userData.personalDetails
                              ? userData.personalDetails.first_name
                              : "-"}
                          </MDTypography>

                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Last Name: &nbsp;
                          </MDTypography>
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="regular"
                            opacity={0.8}
                          >
                            {userData && userData.personalDetails
                              ? userData.personalDetails.last_name
                              : "-"}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center">
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="medium"
                        >
                          Passport Number: &nbsp;
                        </MDTypography>
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="regular"
                          opacity={0.8}
                        >
                          {userData && userData.personalDetails
                            ? userData.personalDetails.passport_number
                            : "-"}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12} xl={12}>
                  <Card
                    id="delete-account"
                    sx={{ border: "2.5px solid #1E3811" }}
                  >
                    <MDBox pt={2} px={2} display="flex" alignItems="center">
                      <AccountBalanceWalletIcon />
                      <MDTypography
                        variant="h6"
                        fontWeight="medium"
                        sx={{ ml: 1 }}
                      >
                        Payment and Status Details
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            borderRadius="lg"
                            sx={{
                              border: "1px solid #1E3811",
                            }}
                          >
                            <MDTypography variant="button">
                              Payment Details
                            </MDTypography>

                            <MDBox mt={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} xl={8}>
                                  {/* <MDBox
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                flexWrap="wrap"
                                justifyContent="space-between"
                                gap={1}
                              > */}
                                  {/* First Transaction Hash */}
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      First Transaction Hash: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="body2"
                                      sx={{
                                        wordBreak: "break-all",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      {userData?.paymentHash || "-"}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>{" "}
                                <Grid item xs={12} xl={2}>
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      Payment Status: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="h6"
                                      color="text"
                                      sx={{
                                        fontSize: 14,
                                        display: "inline-block",
                                      }}
                                    >
                                      {userData?.paymentStatus ? (
                                        userData.paymentStatus ===
                                        "Verified" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="success"
                                            fontWeight="medium"
                                          >
                                            Verified
                                          </MDTypography>
                                        ) : userData.paymentStatus ===
                                          "InCoupon" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="warning"
                                            fontWeight="medium"
                                          >
                                            InCoupon
                                          </MDTypography>
                                        ) : userData.paymentStatus ===
                                          "UnVerified" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="error"
                                            fontWeight="medium"
                                          >
                                            UnVerified
                                          </MDTypography>
                                        ) : (
                                          <MDTypography variant="body2">
                                            Pending
                                          </MDTypography>
                                        )
                                      ) : (
                                        "-"
                                      )}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>{" "}
                                <Grid item xs={12} xl={2}>
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      Token ID: &nbsp;
                                    </MDTypography>
                                    <MDTypography variant="body2">
                                      {userData?.tokenId || "0"}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                {/* </MDBox> */}
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} xl={8}>
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      Second Transaction Hash: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="body2"
                                      sx={{
                                        wordBreak: "break-all",
                                        maxWidth: "100%",
                                      }}
                                    >
                                      {userData?.paymentHashSecond || "-"}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} xl={2}>
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      Payment Status: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="h6"
                                      color="text"
                                      sx={{
                                        fontSize: 14,
                                        display: "inline-block",
                                      }}
                                    >
                                      {userData?.paymentStatusSecond ? (
                                        userData.paymentStatusSecond ===
                                        "Verified" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="success"
                                            fontWeight="medium"
                                          >
                                            Verified
                                          </MDTypography>
                                        ) : userData.paymentStatusSecond ===
                                          "InCoupon" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="warning"
                                            fontWeight="medium"
                                          >
                                            InCoupon
                                          </MDTypography>
                                        ) : userData.paymentStatusSecond ===
                                          "UnVerified" ? (
                                          <MDTypography
                                            variant="body2"
                                            color="error"
                                            fontWeight="medium"
                                          >
                                            UnVerified
                                          </MDTypography>
                                        ) : (
                                          <MDTypography variant="body2">
                                            Pending
                                          </MDTypography>
                                        )
                                      ) : (
                                        "-"
                                      )}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>{" "}
                                <Grid item xs={12} xl={2}>
                                  <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", md: "row" }}
                                  >
                                    <MDTypography
                                      variant="h6"
                                      color="dark"
                                      fontWeight="medium"
                                    >
                                      Token ID: &nbsp;
                                    </MDTypography>
                                    <MDTypography variant="body2">
                                      {userData?.tokenIdSecond || "0"}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>
                              </Grid>

                              <MDBox
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                              >
                                <MDTypography
                                  variant="h6"
                                  color="dark"
                                  fontWeight="medium"
                                >
                                  Coupon Available: &nbsp;
                                </MDTypography>
                                <MDTypography variant="body2">
                                  {userData?.isCoupon === true
                                    ? "Available"
                                    : "Not Available"}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            borderRadius="lg"
                            sx={{
                              border: "1px solid #1E3811",
                            }}
                          >
                            <MDTypography variant="button">
                              Status Details
                            </MDTypography>{" "}
                            <MDBox mt={1}>
                              <MDTypography
                                variant="h6"
                                color="dark"
                                fontWeight="medium"
                                mb={2}
                                // mt={3}
                              >
                                {userData?.status
                                  ? userData.status === "Under_review"
                                    ? "Under Review"
                                    : userData.status === "Processing"
                                    ? "Approved! Creating NFT Soon 😊"
                                    : userData.status
                                  : "Document Submission Incomplete"}
                              </MDTypography>
                              <MDBox ml="auto" lineHeight={0} color="dark">
                                <MDTypography
                                  variant="caption"
                                  color="text"
                                  fontWeight="regular"
                                  sx={{ fontSize: 15, color: "#484747" }}
                                >
                                  {userData?.description
                                    ? userData.description
                                    : " "}
                                </MDTypography>
                              </MDBox>
                            </MDBox>{" "}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card id="delete-account">
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={3}
                  px={2}
                >
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Uploaded Documents
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData
                        ? formatDate(
                            userData.updatedAt ??
                              userData.createdAt ??
                              new Date()
                          )
                        : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          {userData &&
                          userData.documents &&
                          userData.documents ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={1}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <MyDocument pdfFile={userData.documentUrls} />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    Passport
                                  </MDTypography>
                                  <MDTypography variant="body2">
                                    No document has been uploaded yet.
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: "100%" }}>
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Face Recognition
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData
                        ? formatDate(
                            userData.updatedAt ??
                              userData.createdAt ??
                              new Date()
                          )
                        : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox pt={1} pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          {userData &&
                          userData.faceDocument &&
                          userData.faceDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.faceCaptureUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: "100%",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    Face Recognition
                                  </MDTypography>
                                  <MDTypography variant="body2">
                                    No live face capture has been posted as of
                                    yet.
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={3}
                  px={2}
                >
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Uploaded Photo
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                    >
                      {userData
                        ? formatDate(
                            userData.updatedAt ??
                              userData.createdAt ??
                              new Date()
                          )
                        : "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor="grey-100"
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems={{ xs: "flex-start", sm: "center" }}
                          flexDirection={{ xs: "column", sm: "row" }}
                          mb={2}
                        >
                          {userData &&
                          userData.photoDocument &&
                          userData.photoDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.photoUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: "100%",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({
                                    borders: { borderWidth, borderColor },
                                  }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="medium"
                                  >
                                    Photo
                                  </MDTypography>
                                  <MDTypography variant="body2">
                                    No photo has been uploaded yet.
                                  </MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}users/update-user-details/${userData.address}`,
                {
                  method: "POST",
                  body: JSON.stringify(formJson),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response.ok) {
                const result = await response.json();
                toast.success(result.message);
                dispatch(getUserProfileData(token));
                handleClose();
                navigate("/profile");
              } else {
                const error = await response.json();
                toast.error(error.message);
              }
            } catch (error) {
              console.error("Error:", error);
            }
          },
        }}
      >
        <DialogTitle>Update Person Details</DialogTitle>
        <DialogContent dividers>
          {" "}
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            margin="dense"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            // error={!!errors.first_name}
          />{" "}
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            margin="dense"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            // error={!!errors.last_name}
          />{" "}
          <TextField
            required
            id="passport_number"
            name="passport_number"
            label="Passport Number"
            type="text"
            fullWidth
            margin="dense"
            value={passport_number}
            onChange={(e) => setPassportNumber(e.target.value)}
            inputProps={{
              pattern: "^[^\\s]*$",
              minLength: 6,
              maxLength: 10,
            }}
            // error={!!errors.passport_number}
            helperText={
              "No spaces allowed, and length should be between 6 to 9 characters"
            }
          />{" "}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton type="submit" color="info">
            Update
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Profile;
