// @mui material components
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import DocumentImage from "assets/images/smallIcons/img1.png";
import FaceImage from "assets/images/smallIcons/img2.png";
import PhotoImage from "assets/images/smallIcons/img3.png";
import StatusImage from "assets/images/smallIcons/img5.png";
import PaymentImage from "assets/images/smallIcons/img4.png";
import MDButton from "components/MDButton";
import ProgressBar from "components/ProgressBar";
import DocumentDarkImage from "assets/images/icons/selected/Document.png";
import CameratDarkImage from "assets/images/icons/selected/Camera.png";
import PhotoDarkImage from "assets/images/icons/selected/Vector.png";
import PaymentDarkImage from "assets/images/icons/selected/beta.png";
import StatusDarkImage from "assets/images/icons/selected/Vector-1.png";
import HandshakeImage from "assets/images/handshake2.png";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import pattern from "assets/images/profile-bg.png";
function Dashboard() {
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("token");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  // const [userPaymentData, setUserPaymentData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const dispatch = useDispatch();

  const { userDetails, loading } = useSelector((state) => state.user);

  useEffect(() => {
    // setLoading(true);
    if (token) {
      dispatch(getUserProfileData(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, token, navigate]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
      // setLoading(false);
    }
  }, [userDetails]);

  // const fetchUserCouponData = useCallback(async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const results = await response.json();
  //     setUserPaymentData(results.data);
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message || "Upload failed. Please try again.";
  //     console.log(errorMessage);
  //   }
  // }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/");
        return;
      }
      // await fetchUserCouponData();
    };
    fetchData();
  }, [token, navigate]);

  useEffect(() => {
    if (userData && userData.status === "Completed") {
      setIsSuccessDialogOpen(true);
    }
  }, [userData]);

  const rawNftData = localStorage.getItem("nftdata");
  const nftMinting = !isNaN(rawNftData) ? rawNftData : 0;

  const handleRedirectLink = () => {
    navigate("/nft-payment");
  };
  console.log(userData);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {nftMinting > 0 ? (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              <MDBox display="flex" justifyContent="center" mb={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="center">
                      <Marquee gradient={false} speed={130}>
                        <Alert
                          severity="success"
                          icon={
                            <CheckCircleIcon fontSize="inherit" color="info" />
                          }
                          variant="outlined"
                          sx={{
                            border: "2.5px solid #34601D",
                            borderRadius: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <AlertTitle sx={{ fontSize: "16px" }}>
                            {" "}
                            Awesome! Your NFT is now minted and secured!
                          </AlertTitle>
                          Your Digital asset is officially minted and recorded.
                        </Alert>
                      </Marquee>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>

              <Card
                sx={{
                  background:
                    "linear-gradient(0deg, #222222 48.62%, #1E3811 100%)",
                  position: "relative",
                }}
              >
                <MDBox
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  sx={{
                    backgroundImage: `url(${pattern})`,
                    backgroundSize: "cover",
                  }}
                />
                <MDBox position="relative" zIndex={2} p={2}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap={1}
                    sx={{ mt: 2 }}
                  >
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="medium"
                      >
                        First Transaction Hash: &nbsp;
                      </MDTypography>{" "}
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="regular"
                        opacity={0.8}
                        sx={{
                          fontSize: 14,
                          wordBreak: "break-all",
                          maxWidth: "100%",
                        }}
                      >
                        {userData && userData.paymentHash
                          ? userData.paymentHash
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap={1}
                  >
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="medium"
                      >
                        Payment Status: &nbsp;
                      </MDTypography>
                      {userData?.paymentStatus ? (
                        userData.paymentStatus === "Verified" ? (
                          <MDTypography
                            variant="body2"
                            color="success"
                            fontWeight="medium"
                          >
                            Verified
                          </MDTypography>
                        ) : userData.paymentStatus === "InCoupon" ? (
                          <MDTypography
                            variant="body2"
                            color="warning"
                            fontWeight="medium"
                          >
                            InCoupon
                          </MDTypography>
                        ) : userData.paymentStatus === "UnVerified" ? (
                          <MDTypography
                            variant="body2"
                            color="error"
                            fontWeight="medium"
                          >
                            UnVerified
                          </MDTypography>
                        ) : (
                          <MDTypography variant="body2" color="white">
                            Pending
                          </MDTypography>
                        )
                      ) : (
                        "-"
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography
                      variant="h5"
                      color="white"
                      fontWeight="medium"
                    >
                      Token ID: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="h5"
                      color="white"
                      fontWeight="regular"
                    >
                      {userData?.tokenId || "0"}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox position="relative" zIndex={2} p={2}>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap={1}
                    sx={{ mt: 2 }}
                  >
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="medium"
                      >
                        Second Transaction Hash: &nbsp;
                      </MDTypography>{" "}
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="regular"
                        opacity={0.8}
                        sx={{
                          fontSize: 14,
                          wordBreak: "break-all",
                          maxWidth: "100%",
                        }}
                      >
                        {userData && userData.paymentHashSecond
                          ? userData.paymentHashSecond
                          : "-"}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap={1}
                  >
                    <MDBox
                      display="flex"
                      flexDirection={{ xs: "column", md: "row" }}
                    >
                      <MDTypography
                        variant="h6"
                        color="white"
                        fontWeight="medium"
                      >
                        Payment Status: &nbsp;
                      </MDTypography>
                      {userData?.paymentStatusSecond ? (
                        userData.paymentStatusSecond === "Verified" ? (
                          <MDTypography
                            variant="body2"
                            color="success"
                            fontWeight="medium"
                          >
                            Verified
                          </MDTypography>
                        ) : userData.paymentStatusSecond === "InCoupon" ? (
                          <MDTypography
                            variant="body2"
                            color="warning"
                            fontWeight="medium"
                          >
                            InCoupon
                          </MDTypography>
                        ) : userData.paymentStatusSecond === "UnVerified" ? (
                          <MDTypography
                            variant="body2"
                            color="error"
                            fontWeight="medium"
                          >
                            UnVerified
                          </MDTypography>
                        ) : (
                          <MDTypography variant="body2" color="white">
                            Pending
                          </MDTypography>
                        )
                      ) : (
                        "-"
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" alignItems="center">
                    <MDTypography
                      variant="h5"
                      color="white"
                      fontWeight="medium"
                    >
                      Token ID: &nbsp;
                    </MDTypography>
                    <MDTypography
                      variant="h5"
                      color="white"
                      fontWeight="regular"
                    >
                      {userData?.tokenIdSecond || "0"}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox
                  position="relative"
                  p={2}
                  m={2}
                  borderRadius="lg"
                  sx={{
                    border: "1px solid #fff",
                  }}
                >
                  <MDTypography color="white" variant="h6">
                    Apply for Second KYC?
                  </MDTypography>{" "}
                  <MDTypography color="white" variant="body2" mb={4}>
                    Don't miss out on this unique opportunity to own a
                    one-of-a-kind digital asset, and purchase now for you and
                    your loved-ones. Join us now!
                  </MDTypography>{" "}
                  <MDButton
                    onClick={() => handleRedirectLink()}
                    variant="contained"
                    size="large"
                    color="info"
                  >
                    Make payment for 2nd KYC
                  </MDButton>
                </MDBox>
              </Card>
            </>
          )}
        </MDBox>
      ) : (
        <MDBox py={3}>
          {loading ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress size={60} color="primary" />
              <Typography variant="h6" ml={2}>
                Loading your data...
              </Typography>
            </MDBox>
          ) : (
            <>
              {userData?.status ? (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="center">
                        <Marquee gradient={false} speed={130}>
                          <Alert
                            severity="info"
                            variant="outlined"
                            sx={{ borderWidth: 3 }}
                          >
                            <AlertTitle>
                              {{
                                Under_review: "Under Review",
                                Processing: "Creating NFT",
                                Block: "Block",
                                Resubmission: "Resubmission",
                                Rejected: "Rejected",
                                Completed: "Completed",
                              }[userData.status] || ""}
                            </AlertTitle>
                            {{
                              Under_review:
                                "We appreciate your patience as we review your documents; an administrator will be in contact with you soon.",
                              Processing: "Approved! Creating NFT Soon 😊",
                              Block:
                                "You have been blocked by the administrator; please get in touch with our help desk. ",
                              Resubmission:
                                "Please make sure your documents are in clear view so the admin can quickly inspect and confirm that.",
                              Rejected:
                                "You are no longer able to upload documents since the admin rejected you. ",
                              Completed:
                                "We are happy 😊 to announce that after reviewing your documents. You have successfully passed the KYC. We will be providing the NFT soon to the wallet address you have registered.",
                            }[userData.status] || ""}
                            <MDTypography mt={1} color="warning">
                              {" "}
                              {{
                                Processing: userData.description,
                                Block: userData.description,
                                Resubmission: userData.description,
                                Rejected: userData.description,
                                Completed: userData.description,
                              }[userData.status] || ""}
                            </MDTypography>
                          </Alert>
                        </Marquee>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              ) : userData?.isCoupon ? (
                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="center">
                        <Marquee gradient={false} speed={130}>
                          <Alert
                            severity="info"
                            variant="outlined"
                            sx={{ borderWidth: 3 }}
                          >
                            <AlertTitle>Coupon Applied</AlertTitle>
                            Coupon Applied Successfully 😊, Enjoy the ICB KYC
                            without Payment
                          </Alert>
                        </Marquee>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              ) : null}

              <ProgressBar
                activeStep={userData && userData.status === "Completed" ? 5 : 0}
              />

              <MDBox>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? DocumentDarkImage
                                    : DocumentImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              flexGrow: 1,
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Document Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? CameratDarkImage
                                    : FaceImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Face Verification
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? PhotoDarkImage
                                    : PhotoImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Upload Photo
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? PaymentDarkImage
                                    : PaymentImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Payment Process
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={2}>
                    <MDBox mb={3}>
                      <Card
                        sx={{
                          border: `1px solid ${
                            userData && userData.status === "Completed"
                              ? "#3A8A11"
                              : `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`
                          }`,
                          height: "100%", // Ensures card takes up full height
                          display: "grid",
                          gridTemplateRows: "auto 1fr auto",
                        }}
                      >
                        <MDBox padding="1rem" mt={1}>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MDBox display="flex" justifyContent="center">
                              <img
                                alt="Icon"
                                src={
                                  userData && userData.status === "Completed"
                                    ? StatusDarkImage
                                    : StatusImage
                                }
                              />
                            </MDBox>
                          </MDBox>

                          <MDBox
                            pt={3}
                            pb={1}
                            px={1}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h6"
                              textTransform="capitalize"
                              sx={{
                                color: `${
                                  userData && userData.status === "Completed"
                                    ? "#3A8A11"
                                    : "#BCBCBC"
                                }`,
                              }}
                            >
                              Check Status
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox textAlign="center" mt={10}>
                  <MDBox>
                    <Typography variant="h1">Welcome to ICB KYC</Typography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      Please upload the related documents to continue
                    </MDTypography>
                  </MDBox>
                  <MDBox textAlign="center" mb={2}>
                    <Typography variant="h4">
                      Your Token ID is {userData?.tokenId}
                    </Typography>
                  </MDBox>
                  <MDBox>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      to="/documents"
                      sx={{
                        background:
                          "linear-gradient(94deg, #50811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </MDBox>
                </MDBox>

                <MDBox display="flex" justifyContent="center" mb={4}>
                  <Dialog
                    open={isSuccessDialogOpen}
                    onClose={handleDialogSuccessClose}
                    aria-labelledby="error-dialog-title"
                    aria-describedby="error-dialog-description"
                    fullWidth={true} // Ensures the dialog stretches as per the content
                  >
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      p={6}
                    >
                      <img alt="document" src={HandshakeImage} width={100} />

                      <DialogTitle id="error-dialog-title" align="center">
                        Congratulation! 🥳
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="error-dialog-description"
                          align="center"
                        >
                          We are happy 😊 to announce that after reviewing your
                          documents. You have successfullly based the KYC. We
                          will be providing NFT Soon on the wallet address you
                          have resigtered.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <MDButton
                          onClick={handleDialogSuccessClose}
                          variant="contained"
                          size="large"
                          sx={{
                            background:
                              "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "darkgreen",
                            },
                          }}
                        >
                          OK
                        </MDButton>
                      </DialogActions>
                    </MDBox>
                  </Dialog>
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
